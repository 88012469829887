export function Signout({ supabase, email }) {
  async function signOut() {
    await supabase.auth.signOut();
  }

  return (
    <div className="footer">
      {email}
      <button onClick={() => signOut()}>Logga ut</button>
    </div>
  );
}
