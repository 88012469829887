import { useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { LanguageSelector } from "../components/LanguageSelector";
import {
  auth_translation_SV,
  auth_translation_EN,
} from "../assets/constants/translations";

export default function Authenticator({
  session,
  setSession,
  supabase,
  setUserID,
  setEmai,
}) {
  const [language, setLanguage] = useState("sv");

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUserID(session?.user.id);
      setEmai(session?.user.email);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUserID(session?.user.id);
      setEmai(session?.user.email);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <>
        <LanguageSelector setLanguage={setLanguage} />
        <div className="loginDiv">
          <div>Disneypussel</div>
          <Auth
            supabaseClient={supabase}
            showLinks={true}
            appearance={{
              theme: ThemeSupa,
            }}
            providers={[]}
            localization={
              language === "sv" ? auth_translation_SV : auth_translation_EN
            }
          />
        </div>
        <LoginFooter />
      </>
    );
  }
}

function LoginFooter() {
  return <div className="loginFooter">Background: Freepik</div>;
}
