import { useState } from "react";

export function Modal({
  toggleModal,
  activePussle,
  puzzleList,
  supabase,
  setRefresh,
}) {
  const selectedPuzzle = puzzleList.filter((y) => y.id === activePussle);
  const [own, setOwn] = useState(selectedPuzzle[0].own);

  function toggleOwn(value) {
    setRefresh(Math.random());
    setOwn((exp) => !exp);
    const sqlStatement = selectedPuzzle[0].recordexist ? "Update" : "Insert";

    if (sqlStatement === "Insert") {
      insertSQL(selectedPuzzle[0].userid, selectedPuzzle[0].id);
    }
    if (sqlStatement === "Update") {
      updateSQL(selectedPuzzle[0].recordexist);
    }
  }

  async function insertSQL(userID, puzzleID) {
    const { data, error } = await supabase
      .from("userpuzzle")
      .insert([{ userid: userID, puzzleid: puzzleID, own: !own }])
      .select();
  }

  async function updateSQL(userPuzzleID) {
    const { data, error } = await supabase
      .from("userpuzzle")
      .update({ own: !own })
      .eq("id", userPuzzleID)
      .select();
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modalImage">
          <div>
            <h2>{selectedPuzzle[0].name}</h2>
          </div>
          <div className="modalImageDIV">
            <img
              src={`puzzel_images/${selectedPuzzle[0].image}.webp`}
              className="puzzleimage"
              alt={selectedPuzzle[0].image}
            />
          </div>
          <div className="parent">
            <div className="checkbox-wrapper-22">
              <label className="switch" htmlFor="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  defaultChecked={own}
                  onChange={() => toggleOwn(own)}
                />
                <div className="slider round"></div>
              </label>
            </div>
            <div className="closeDiv">
              <button className="filterButton" onClick={() => toggleModal()}>
                STÄNG
              </button>
            </div>
          </div>
        </div>
        {own || (
          <div className="bubble">
            <div id="log2">
              <span className="arrow-up"></span>
              Klicka här om du äger pusslet
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
