import { useEffect, useState } from "react";
import { Puzzles } from "./Puzzles";
import { Modal } from "./Modal";
import { Filters } from "./Filters";
import { Signout } from "./Signout";

export default function Dashboard({ userID, supabase, email }) {
  const [filter, setFilter] = useState("All");
  const [modal, setModal] = useState(false);
  const [activePussle, setActivePussle] = useState(0);
  const [puzzleList, setPuzzleList] = useState();
  const [refresh, setRefresh] = useState();
  const [textfilter, settextfilter] = useState("");

  useEffect(() => {
    getPuzzles();
  }, [refresh]);

  async function getPuzzles() {
    let value = userID;
    let { data, error } = await supabase.rpc("getpuzzlesfromdb2", {
      value,
    });
    if (error) {
      console.error(error);
    } else {
      setPuzzleList(data);
    }
  }

  function handleToggleModal(id) {
    setModal(!modal);
    setActivePussle(id);
    if (modal) {
      setRefresh(Math.random());
    }
  }

  return (
    <>
      {modal && (
        <Modal
          modal={modal}
          toggleModal={handleToggleModal}
          activePussle={activePussle}
          puzzleList={puzzleList}
          supabase={supabase}
          setRefresh={setRefresh}
        />
      )}
      <Filters
        filter={filter}
        onSetFilter={setFilter}
        textfilter={textfilter}
        settextfilter={settextfilter}
      />
      <Puzzles
        filter={filter}
        toggleModal={handleToggleModal}
        puzzleList={puzzleList}
        textfilter={textfilter}
      />
      <Signout supabase={supabase} email={email} />
    </>
  );
}
