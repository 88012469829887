export function Puzzles({ filter, toggleModal, puzzleList, textfilter }) {
  return (
    <div className="main">
      <ul className="puzzles">
        <Puzzle
          filter={filter}
          toggleModal={toggleModal}
          puzzleList={puzzleList}
          textfilter={textfilter}
        />
      </ul>
    </div>
  );
}

export function Puzzle({ filter, toggleModal, puzzleList, textfilter }) {
  let filteredArray = [];
  if (textfilter) {
    filteredArray = puzzleList.filter(
      (x) =>
        x.name.toLowerCase().includes(textfilter.toLowerCase()) ||
        textfilter === ""
    );
  } else {
    filter === "Own"
      ? (filteredArray = puzzleList.filter((x) => x.own === true))
      : filter === "Do not own"
      ? (filteredArray = filteredArray =
          puzzleList.filter((x) => x.own === false))
      : (filteredArray = puzzleList);
  }
  return (
    <>
      {filteredArray?.map((x) => (
        <div key={x.id}>
          <div>
            <div className="puzzleDiv">
              <li>
                <img
                  src={`puzzel_images/${x.image}.webp`}
                  className="puzzleimage"
                  alt={x.image}
                  onClick={() => toggleModal(x.id)}
                />
              </li>
              <div className="row">
                <div className="puzzleName column">{x.name}</div>
                <div className="puzzleIcons column2">{x.own ? "🟩" : "🟥"}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
