import { useState } from "react";

export function Filters({ filter, onSetFilter, textfilter, settextfilter }) {
  const [open, setOPen] = useState(false);

  const toggle = () => {
    setOPen(!open);
  };

  function handleChange(e) {
    onSetFilter(e.target.value);
    settextfilter("");
  }

  return (
    <>
      <div style={{ color: "black" }}>
        <div className="filter">
          <div className="grid-container"></div>

          <div className="grid-container box2">
            <select value={filter} onChange={handleChange}>
              <option value="All">Alla</option>
              <option value="Own">Äger</option>
              <option value="Do not own">Äger inte</option>
            </select>
          </div>

          <div className="grid-container box3">
            <button className="expand" onClick={toggle}>
              {open ? "-" : "+"}
            </button>
          </div>
        </div>

        {open && (
          <div className="box1">
            <input
              type="text"
              value={textfilter}
              onChange={(event) => settextfilter(event.target.value)}
              placeholder="Textfilter..."
            ></input>
          </div>
        )}
      </div>
    </>
  );
}
