import { useState } from "react";
import Authenticator from "./components/Authenticator";
import { createClient } from "@supabase/supabase-js";
import Dashboard from "./components/Dashboard";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

export default function App() {
  const [session, setSession] = useState();
  const [userID, setUserID] = useState();
  const [email, setEmai] = useState();

  return (
    <>
      <Authenticator
        session={session}
        setSession={setSession}
        supabase={supabase}
        setUserID={setUserID}
        setEmai={setEmai}
      />
      {!session && <div className="backgroundDIV"></div>}

      {session && (
        <Dashboard userID={userID} supabase={supabase} email={email} />
      )}
    </>
  );
}
