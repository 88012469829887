export const auth_translation_SV = {
  variables: {
    sign_in: {
      email_label: "",
      password_label: "",
      email_input_placeholder: "E-post",
      password_input_placeholder: "Lösenord",
      button_label: "Logga in",
      link_text: "Har du redan ett konto? Logga in",
    },

    sign_up: {
      email_label: "",
      password_label: "",
      email_input_placeholder: "E-post",
      password_input_placeholder: "Skapa lösenord",
      button_label: "Skapa användare",
      loading_button_label: "Laddar...",
      link_text: "Har du inget konto? Bli Medlem",
      confirmation_text: "Kontrollera din e-post för bekräftelselänken",
    },
    forgotten_password: {
      email_label: "",
      password_label: "Ditt lösenord",
      email_input_placeholder: "E-post",
      button_label: "Skicka instruktioner för återställning av lösenord",
      loading_button_label: "Skickar återställningsinstruktioner...",
      link_text: "Glömt ditt lösenord?",
      confirmation_text: "Kontrollera din e-post för återställning av lösenord",
    },
  },
};

export const auth_translation_EN = {
  variables: {
    sign_in: {
      email_label: "",
      password_label: "",
      email_input_placeholder: "Email address",
      password_input_placeholder: "Your Password",
      button_label: "Sign in",
      link_text: "Already have an account? Sign in",
    },

    sign_up: {
      email_label: "",
      password_label: "",
      email_input_placeholder: "Email address",
      password_input_placeholder: "Your password",
      button_label: "Sign up",
      loading_button_label: "Signing up ...",
      link_text: "Don't have an account? Sign up",
      confirmation_text: "Check your email for the confirmation link",
    },
    forgotten_password: {
      email_label: "",
      password_label: "Your Password",
      email_input_placeholder: "Your email address",
      button_label: "Send reset password instructions",
      loading_button_label: "Sending reset instructions ...",
      link_text: "Forgot your password?",
      confirmation_text: "Check your email for the password reset link",
    },
  },
};
